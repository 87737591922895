import {CustomNode, NodeType} from "../../components/types/types";
import {v4 as uuidv4} from "uuid";

export const useCreateWrapperMoveBlock = () => {

    return (
        wrapperType: string,
        targetBlock: CustomNode,
        parentNode: CustomNode | null,
        grid: CustomNode,
        pageId: string
    ): CustomNode => {
        const wrapper: CustomNode = {
            id: uuidv4(),
            nodeType: wrapperType as NodeType.BLOCK | NodeType.ROW | NodeType.COL,
            children: [targetBlock],
            parentId: parentNode?.id ?? null,
            pageId,
        };

        targetBlock.parentId = wrapper.id;

        if (parentNode?.children) {
            const targetIndex = parentNode.children.findIndex((child) => child.id === targetBlock.id);
            parentNode.children.splice(targetIndex, 1, wrapper);
        } else if (!parentNode && grid.children) {
            const targetIndex = grid.children.findIndex((child) => child.id === targetBlock.id);
            grid.children.splice(targetIndex, 1, wrapper);
        }

        return wrapper;
    };
};
