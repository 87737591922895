import React, {useCallback, useEffect} from "react";
import {BlockType, CustomNode, InsertBlockOptions, NodeType, WrapperPosition} from "../../components/types/types";
import DropZones from "./DropZones";
import AddBlockZonesForBlock from "./AddBlockZonesForBlock";
import RenderChildren from "./RenderChildren";
import TextEditor from "./TextEditor";
import {useDispatch, useSelector} from "react-redux";
import {useDeleteBlock} from "../../hooks/keeps/useDeleteBlock";
import {RootState} from "../../redux";
import {setCurrentBlock, setHighlightedNodes, updateBlock} from "../../redux/slices/keeps-slice";

interface BlockComponentProps {
    node: CustomNode;
    handleAddBlock: (position: WrapperPosition, wrapperType: NodeType.ROW | NodeType.COL) => void;
    handleAddBlockIntoWrapper: (position: WrapperPosition) => void;
    handleDeleteBlock: (nodeId: string) => void;
    addSubBlock: (insertBlockOptions: InsertBlockOptions) => void;
    deleteBlock: (blockId: string) => void;
    addBlock: (insertBlockOptions: InsertBlockOptions) => void;
    moveBlock: (insertBlockOptions: InsertBlockOptions) => void;
    handleDrop: (e: React.DragEvent, position: "before" | "after" | "inside", wrapperType?: NodeType.ROW | NodeType.COL) => void;
    handleDragStart: (e: React.DragEvent) => void;
    handleDragOver: (e: React.DragEvent) => void;
    handleDragEnd: (e: React.DragEvent) => void;
}

const BlockComponent: React.FC<BlockComponentProps> = ({
                                                           node,
                                                           handleAddBlock,
                                                           addSubBlock,
                                                           deleteBlock,
                                                           addBlock,
                                                           moveBlock,
                                                           handleDrop,
                                                           handleDragOver,
                                                           handleDragStart,
                                                           handleDragEnd,
                                                       }) => {
    const dispatch = useDispatch();

    const highlightedNodes = useSelector((state: RootState) => state.keeps.present.highlightedNodes);
    const currentBlock = useSelector((state: RootState) => state.keeps.present.currentBlock);

    const deleteBlockInBLockCompoonent = useDeleteBlock();

    const unsetNode = () => {
        dispatch(setHighlightedNodes(null)); // Убираем подсветку
        dispatch(setCurrentBlock(null)); // Сбрасываем текущий блок
    };

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.key === "Escape") {
            if (highlightedNodes && highlightedNodes.length) {
                console.log("второй esc");
                unsetNode();
            } else if (currentBlock) {
                console.log("первый esc");
                console.log(currentBlock.id, node.id);
                dispatch(setHighlightedNodes([currentBlock]));
            }
        }
        if (event.key === "Delete" && highlightedNodes) {
            deleteBlockInBLockCompoonent(highlightedNodes[0].id);
            unsetNode();
        }
        // eslint-disable-next-line
    }, [currentBlock, highlightedNodes, node, unsetNode, deleteBlockInBLockCompoonent]);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedBlock = {
            ...node,
            checked: e.target.checked,
        };
        dispatch(updateBlock(updatedBlock));
    };

    const handleToggle = () => {
        const updatedBlock = {
            ...node,
            minimized: !node.minimized,
        };
        dispatch(updateBlock(updatedBlock));
    };


    const renderBlock = (node: CustomNode) => {
        switch (node.blockType) {
            case BlockType.text:
                return <TextEditor key={node.id} node={node} handleAddBlock={handleAddBlock}/>;
            case BlockType.checkbox:
                return (
                    <>
                        <input
                            type="checkbox"
                            className="mr-2"
                            defaultChecked={node.checked}
                            onChange={handleCheckboxChange}
                        />
                        <TextEditor key={node.id} node={node} handleAddBlock={handleAddBlock}/>
                    </>
                );
            case BlockType.toggle:
                return (
                    <>
                        <button
                            onClick={handleToggle}
                            className={`transition-transform duration-300 mr-1.5 ${
                                node.minimized ? "rotate-0" : "rotate-90"
                            }`}
                        >
                            {/* Иконка стрелочки */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="w-4 h-4 -ml-0.5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 5l7 7-7 7"
                                />
                            </svg>
                        </button>
                        <TextEditor
                            key={node.id}
                            node={node}
                            handleAddBlock={handleAddBlock}
                        />
                    </>
                );
            case BlockType.toggled_checkbox:
                return (
                    <>
                        <button
                            onClick={handleToggle}
                            className={`transition-transform duration-300 mr-1.5 ${
                                node.minimized ? "rotate-0" : "rotate-90"
                            }`}
                        >
                            {/* Иконка стрелочки */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="w-4 h-4 -ml-0.5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 5l7 7-7 7"
                                />
                            </svg>
                        </button>
                        <input
                            type="checkbox"
                            className="mr-2"
                            defaultChecked={node.checked}
                            onChange={handleCheckboxChange}
                        />
                        <TextEditor
                            key={node.id}
                            node={node}
                            handleAddBlock={handleAddBlock}
                        />
                    </>
                );

            default:
                return <div key={node.id}>Неизвестный тип блока</div>;
        }
    };

    return (
        <div className={`block relative cursor-grab`}>
            {highlightedNodes && highlightedNodes.includes(node) && (
                <>
                    <div
                        className="absolute z-10 inset-0 rounded-md bg-highlight pointer-events-none mix-blend-multiply"></div>
                </>
            )}
            <div
                draggable
                onDragStart={(e) => {
                    e.dataTransfer.setData("application/json", JSON.stringify(node));
                    handleDragStart(e);
                }}
                onDragEnd={handleDragEnd}
                className={`px-2 py-0.5 rounded-md hover:bg-gray-200 transition flex relative mt-2 items-center bg-gray-50`}
            >
                <DropZones handleDragOver={handleDragOver} handleDrop={handleDrop}/>
                <AddBlockZonesForBlock
                    handleAddBlock={handleAddBlock}
                />
                {renderBlock(node)}
            </div>
            <div
                className={`sub-block flex flex-col ml-4 max-w-full ${
                    node.blockType === BlockType.toggle || node.blockType === BlockType.toggled_checkbox
                        ? `transition-all duration-300 ease-in-out overflow-hidden ${
                            node.minimized ? "max-h-0 opacity-0" : "max-h-screen opacity-100"
                        }`
                        : ""
                }`}
            >
                <RenderChildren
                    children={node.children}
                    addSubBlock={addSubBlock}
                    deleteBlock={deleteBlock}
                    addBlock={addBlock}
                    moveBlock={moveBlock}
                />
            </div>
        </div>
    );
};

export default BlockComponent;
