import React, {useState} from "react";
import {NodeType} from "../../components/types/types";
import {useSelector} from "react-redux";
import {RootState} from "../../redux";

interface DropZonesProps {
    handleDragOver: (e: React.DragEvent) => void;
    handleDrop: (
        e: React.DragEvent,
        position: "before" | "after" | "inside",
        wrapperType?: NodeType.ROW | NodeType.COL
    ) => void;
}

const DropZones: React.FC<DropZonesProps> = ({
                                                 handleDragOver,
                                                 handleDrop,
                                             }) => {
    const dragging = useSelector((state: RootState) => state.keeps.present.dragging);
    const [hoveredZone, setHoveredZone] = useState<string | null>(null);

    const handleDragEnter = (zone: string) => {
        setHoveredZone(zone);
    };

    const handleDragLeave = () => {
        setHoveredZone(null);
    };

    return (
        <>
            {dragging && (
                <>
                    <div
                        className={`absolute z-50 -top-1.5 left-0 w-full h-1 rounded-md ${
                            hoveredZone === "before-col" ? "bg-gradient-to-r from-teal-100 to-violet-200" : "bg-transparent"
                        }`}
                        onDragOver={(e) => {
                            e.preventDefault();
                            handleDragOver(e);
                        }}
                        onDragEnter={() => handleDragEnter("before-col")}
                        onDragLeave={handleDragLeave}
                        onDrop={(e) => {
                            setHoveredZone(null);
                            handleDrop(e, "before", NodeType.COL);
                        }}
                    ></div>
                    <div
                        className={`absolute z-50 top-0 left-0 w-full h-full rounded-md ${
                            hoveredZone === "inside-col" ? "bg-gradient-to-r from-teal-100 to-violet-200 mix-blend-multiply" : "bg-transparent"
                        }`}
                        onDragOver={(e) => {
                            e.preventDefault();
                            handleDragOver(e);
                        }}
                        onDragEnter={() => handleDragEnter("inside-col")}
                        onDragLeave={handleDragLeave}
                        onDrop={(e) => {
                            setHoveredZone(null);
                            handleDrop(e, "inside", NodeType.COL);
                        }}
                    ></div>
                    <div
                        className={`absolute z-50 -bottom-1.5 left-0 w-full h-1 rounded ${
                            hoveredZone === "after-col" ? "bg-gradient-to-r from-teal-100 to-violet-300" : "bg-transparent"
                        }`}
                        onDragOver={(e) => {
                            e.preventDefault();
                            handleDragOver(e);
                        }}
                        onDragEnter={() => handleDragEnter("after-col")}
                        onDragLeave={handleDragLeave}
                        onDrop={(e) => {
                            setHoveredZone(null);
                            handleDrop(e, "after", NodeType.COL);
                        }}
                    ></div>
                    <div
                        className={`absolute z-50 -left-1.5 top-0 h-full w-1 rounded ${
                            hoveredZone === "before-row" ? "bg-gradient-to-b from-teal-100 to-violet-300" : "bg-transparent"
                        }`}
                        onDragOver={(e) => {
                            e.preventDefault();
                            handleDragOver(e);
                        }}
                        onDragEnter={() => handleDragEnter("before-row")}
                        onDragLeave={handleDragLeave}
                        onDrop={(e) => {
                            setHoveredZone(null);
                            handleDrop(e, "before", NodeType.ROW);
                        }}
                    ></div>
                    <div
                        className={`absolute z-50 -right-1.5 top-0 h-full w-1 rounded ${
                            hoveredZone === "after-row" ? "bg-gradient-to-b from-teal-100 to-violet-300" : "bg-transparent"
                        }`}
                        onDragOver={(e) => {
                            e.preventDefault();
                            handleDragOver(e);
                        }}
                        onDragEnter={() => handleDragEnter("after-row")}
                        onDragLeave={handleDragLeave}
                        onDrop={(e) => {
                            setHoveredZone(null);
                            handleDrop(e, "after", NodeType.ROW);
                        }}
                    ></div>
                </>
            )}
        </>
    );
};

export default DropZones;
