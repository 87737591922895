import React from "react";
import {BlockType, CustomNode, InsertBlockOptions, NodeType, WrapperPosition} from "../../components/types/types";
import RenderChildren from "./RenderChildren";
import AddBlockZonesForRow from "./AddBlockZonesForRow";
import AddBlockZonesForCol from "./AddBlockZonesForCol";
import BlockComponent from "./BlockComponent";
import {useDispatch} from "react-redux";
import {setDragging} from "../../redux/slices/keeps-slice";

interface RenderNodeProps {
    node: CustomNode;
    addSubBlock: (insertBlockOptions: InsertBlockOptions) => void; // Add sub
    deleteBlock: (blockId: string) => void;
    addBlock: (insertBlockOptions: InsertBlockOptions) => void;
    moveBlock: (insertBlockOptions: InsertBlockOptions) => void;
}


const RenderNode: React.FC<RenderNodeProps> = ({
                                                   node,
                                                   addSubBlock,
                                                   deleteBlock,
                                                   addBlock,
                                                   moveBlock
                                               }) => {
    const dispatch = useDispatch();

    const handleAddBlock = (
        position: WrapperPosition,
        wrapperType: NodeType.ROW | NodeType.COL,
    ) => {
        const parentNodeId = node.parentId || node.id;

        addBlock({
                parentNodeId,
                currentBlockId: node.id,
                blockType: BlockType.text, // Тип блока по умолчанию
                position,
                wrapperType
            }
        );
    };


    const handleAddBlockIntoWrapper = (
        position: WrapperPosition,
    ) => {
        const parentNodeId = node.id;

        addBlock(
            {
                parentNodeId,
                currentBlockId: node.id,
                blockType: BlockType.text, // Тип блока по умолчанию
                position,
            },
        );
    };

    const handleDeleteBlock = (nodeId: string) => {
        deleteBlock(nodeId); // Удаляем узел с указанным идентификатором
    };

    const handleDrop = (
        e: React.DragEvent,
        position: "before" | "after" | "inside",
        wrapperType?: NodeType.ROW | NodeType.COL,
    ) => {
        dispatch(setDragging(false));

        e.preventDefault();

        try {
            // Получаем данные перетаскивания
            const draggedData = e.dataTransfer.getData("application/json");

            if (!draggedData) {
                console.error("Данные перетаскивания отсутствуют или пусты");
                return;
            }

            let draggedNode: { id: string } | null = null;

            try {
                // Парсим JSON данные
                draggedNode = JSON.parse(draggedData);
            } catch (jsonError) {
                console.error("Ошибка парсинга JSON данных перетаскивания:", jsonError);
                return;
            }

            // Проверяем корректность данных
            if (!draggedNode || !draggedNode.id) {
                console.error("Некорректный формат данных перетаскивания:", draggedNode);
                return;
            }

            // Проверяем, что перетаскиваемый элемент не совпадает с целевым узлом
            if (draggedNode.id !== node.id) {
                moveBlock({
                    currentBlockId: draggedNode.id,
                    parentNodeId: node.parentId ?? "",
                    targetBlockId: node.id,
                    blockType: node.blockType as BlockType,
                    position,
                    wrapperType,
                });
            } else {
                console.warn("Перетаскиваемый блок совпадает с целевым, операция пропущена");
            }
        } catch (error) {
            // Общая обработка ошибок
            console.error("Ошибка в процессе обработки перетаскивания:", error);
        }
    };


    const handleDragStart = (e: React.DragEvent) => {
        e.dataTransfer.setData(
            "application/json",
            JSON.stringify(node)
        );
        e.dataTransfer.effectAllowed = "move";
        dispatch(setDragging(true));
    };

    const handleDragOver = (e: React.DragEvent) => {
        // e.preventDefault(); // Позволяет дроп в область
        e.dataTransfer.dropEffect = "move";
    };

    const handleDragEnd = (e: React.DragEvent) => {
        e.preventDefault(); // Позволяет дроп в область
        e.dataTransfer.dropEffect = "move";
        dispatch(setDragging(false));
    };


    const renderConfig: Record<string, React.ReactNode> = {
        ROW: (
            <div className="row relative">
                <AddBlockZonesForRow handleAddBlock={handleAddBlock}
                                     handleAddBlockIntoWrapper={handleAddBlockIntoWrapper}/>
                <div className="flex flex-row w-full max-w-full 1bg-yellow-300 space-x-4 1hover:bg-yellow-100 m-1">
                    {/*{node.id}*/}
                    {/*<DropZones handleDragOver={handleDragOver} handleDrop={handleDrop} node={node}/>*/}
                    <RenderChildren children={node.children}
                                    addSubBlock={addSubBlock} deleteBlock={deleteBlock} addBlock={addBlock}
                                    moveBlock={moveBlock}/>
                </div>
            </div>
        ),
        COL: (
            <div className="col relative">
                <AddBlockZonesForCol handleAddBlock={handleAddBlock}
                                     handleAddBlockIntoWrapper={handleAddBlockIntoWrapper}/>
                <div className="flex flex-col max-w-full 1bg-green-300 1shadow m-1 1hover:bg-green-100">
                    {/*{node.id}*/}
                    {/*<DropZones handleDragOver={handleDragOver} handleDrop={handleDrop} node={node}/>*/}
                    <RenderChildren children={node.children}
                                    addSubBlock={addSubBlock} deleteBlock={deleteBlock} addBlock={addBlock}
                                    moveBlock={moveBlock}/>
                </div>
            </div>
        ),
        BLOCK: (
            <BlockComponent
                node={node}
                handleAddBlock={handleAddBlock}
                handleAddBlockIntoWrapper={handleAddBlockIntoWrapper}
                handleDeleteBlock={handleDeleteBlock}
                addSubBlock={addSubBlock}
                deleteBlock={deleteBlock}
                addBlock={addBlock}
                moveBlock={moveBlock}
                handleDrop={handleDrop}
                handleDragOver={handleDragOver}
                handleDragEnd={handleDragEnd}
                handleDragStart={handleDragStart}
            />
        ),
    };

    return <div
        className={`node-container w-full ${node.nodeType}`}>{renderConfig[node.nodeType]}</div>;
};

export default RenderNode;
