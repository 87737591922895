import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {updateBlock} from "../../redux/slices/keeps-slice";
import {CustomNode, NodeType} from "../../components/types/types";
import {useFindNodeById} from "./useFindNodeById";

export const useConvertToSubBlock = () => {
    const dispatch = useDispatch();
    const findNodeById = useFindNodeById();
    const grid = useSelector((state: RootState) => state.keeps.present.grid);

    return (currentBlock: CustomNode) => {
        console.log("convertToSubBlock", currentBlock);
        if (!grid) {
            console.error("Сетка не инициализирована");
            return;
        }

        if (!currentBlock.parentId) {
            console.error("У текущего блока нет родителя");
            return;
        }

        const parent = findNodeById(currentBlock.parentId);
        if (!parent || !parent.children) {
            console.error("Родительский элемент не найден или не имеет детей");
            return;
        }

        const currentIndex = parent.children.findIndex((child: CustomNode) => child.id === currentBlock.id);
        if (currentIndex === -1) {
            console.error("Текущий блок не найден среди детей родителя");
            return;
        }

        if (currentIndex === 0) {
            console.error("Текущий блок является первым в массиве детей");
            return;
        }

        const prevSibling = parent.children[currentIndex - 1];

        if (prevSibling.children?.length) {
            const lastChild = prevSibling.children[prevSibling.children.length - 1];

            if (lastChild.nodeType === NodeType.COL) {
                const updatedCurrentBlock = {
                    ...currentBlock,
                    parentId: lastChild.id,
                };

                const updatedLastChild = {
                    ...lastChild,
                    children: [...lastChild.children, updatedCurrentBlock],
                };

                const updatedParent = {
                    ...parent,
                    children: parent.children.filter((child: CustomNode) => child.id !== currentBlock.id),
                };

                dispatch(updateBlock([updatedCurrentBlock, updatedParent, updatedLastChild]));
                console.log("Блок перемещён в последнюю колонку предыдущего блока");
                return;
            }
        }

        if (!prevSibling.children) {
            console.error("Предыдущий блок не может содержать детей");
            return;
        }

        const updatedCurrentBlock = {
            ...currentBlock,
            parentId: prevSibling.id,
        };

        const updatedPrevSibling = {
            ...prevSibling,
            children: [...prevSibling.children, updatedCurrentBlock],
        };

        const updatedParent = {
            ...parent,
            children: parent.children.filter((child: CustomNode) => child.id !== currentBlock.id),
        };

        dispatch(updateBlock([updatedCurrentBlock, updatedParent, updatedPrevSibling]));
        console.log("Блок преобразован в подблок предыдущего блока");
    };
};
