import React, {useEffect, useRef, useState} from "react";
import {EditorState, Plugin, PluginKey, TextSelection} from "prosemirror-state";
import {EditorView} from "prosemirror-view";
import {DOMParser, Schema} from "prosemirror-model";
import {schema as basicSchema} from "prosemirror-schema-basic";
import {keymap} from "prosemirror-keymap";
import {history} from "prosemirror-history";
import {baseKeymap} from "prosemirror-commands";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {setCurrentBlock, setCursorPosition, setHighlightedNodes, updateBlock} from "../../redux/slices/keeps-slice";
import {CustomNode, NodeType} from "../../components/types/types";
import {useConvertToSubBlock} from "../../hooks/keeps/useConvertToSubBlock";
import {useConvertSubBlockToBlock} from "../../hooks/keeps/useConvertSubBlockToBlock";
import {useConvertToCheckBox} from "../../hooks/keeps/useConvertToCheckBox";
import {useConvertToToggle} from "../../hooks/keeps/useConvertToToggle";
import {useConvertBlockOnBackspace} from "../../hooks/keeps/useConvertBlockOnBackspace";
import {useConvertToToggledCheckbox} from "../../hooks/keeps/useConvertToToggledCheckbox";

const TextEditor = ({node, handleAddBlock}: {
    node: CustomNode;
    handleAddBlock: (position: "before" | "after", wrapperType: NodeType.ROW | NodeType.COL) => void
}) => {
    const dispatch = useDispatch();
    const editorRef = useRef<HTMLDivElement>(null);
    const viewRef = useRef<EditorView | null>(null);

    const cursorPosition = useSelector((state: RootState) => state.keeps.present.position);
    const currentNodeFromRedux = useSelector((state: RootState) => state.keeps.present.currentBlock);
    const highlightedNodes = useSelector((state: RootState) => state.keeps.present.highlightedNodes);

    const [localText, setLocalText] = useState(node.text || "");

    const convertToSubBlock = useConvertToSubBlock();
    const convertToBlock = useConvertSubBlockToBlock();
    const convertToCheckBox = useConvertToCheckBox();
    const convertToToggle = useConvertToToggle();
    const convertToToggledCheckbox = useConvertToToggledCheckbox();
    const convertBlockOnBackspace = useConvertBlockOnBackspace();

    const createDocumentFromText = (text: string, schema: Schema) => {
        const parser = new window.DOMParser();
        const content = parser.parseFromString(`<p>${text}</p>`, "text/html").body;
        return DOMParser.fromSchema(schema).parse(content);
    };

    const handleTextUpdate = (text: string) => {
        if (/^\[]\s/.test(text)) {
            const newText = text.slice(3).trim();
            setLocalText(newText)
            const updatedBlock = convertToCheckBox({...node, text: newText} as CustomNode);

            if (updatedBlock) {
                dispatch(updateBlock(updatedBlock));
                dispatch(setCursorPosition(1));
            }
        } else if (/^>\s/.test(text)) {
            const newText = text.slice(2).trim();
            setLocalText(newText)
            const updatedBlock = convertToToggle({...node, text: newText} as CustomNode);

            if (updatedBlock) {
                dispatch(updateBlock(updatedBlock));
                dispatch(setCursorPosition(1));
            }
        } else if (/^\[]>\s/.test(text)) {
            const newText = text.slice(4).trim();
            setLocalText(newText)
            const updatedBlock = convertToToggledCheckbox({...node, text: newText} as CustomNode);

            if (updatedBlock) {
                dispatch(updateBlock(updatedBlock));
                dispatch(setCursorPosition(1));
            }
        } else if (text !== node.text) {
            setLocalText(text); // Обновляем локальный текст при каждом изменении
        }
    };

    const initializeEditorState = () =>
        EditorState.create({
            schema: basicSchema,
            doc: createDocumentFromText(node.text || "", basicSchema),
            plugins: [
                history(),
                keymap({
                    ...baseKeymap,
                    Enter: () => {
                        handleAddBlock("after", NodeType.COL);
                        return true;
                    },
                    Tab: () => {
                        setLocalText((prevText) => {
                            convertToSubBlock({
                                ...node,
                                text: prevText, // Гарантируем, что используется последнее состояние
                            });
                            return prevText;
                        });
                        return true;
                    },
                    "Shift-Tab": () => {
                        setLocalText((prevText) => {
                            try {
                                convertToBlock({
                                    ...node,
                                    text: prevText, // Гарантируем, что используется последнее состояние
                                });
                            } catch (error: unknown) {
                                console.error((error as { message: string }).message);
                            }
                            return prevText;
                        });
                        return true;
                    },
                    Backspace: (state) => {
                        const {$cursor} = state.selection as TextSelection;
                        if ($cursor && $cursor.pos === 1) { // В начале текста
                            console.log("Backspace pressed at the start of the line");
                            setLocalText((prevText) => {
                                convertBlockOnBackspace({
                                    ...node,
                                    text: prevText, // Гарантируем, что используется последнее состояние
                                });
                                return prevText;
                            });
                            return true;
                        }
                        return false; // Позволяет использовать стандартное поведение
                    },
                }),
                new Plugin({
                    key: new PluginKey("trackCursorPosition"),
                    view: () => ({
                        update: (view) => {
                            const selection = view.state.selection as TextSelection;
                            if (selection.empty && selection.$cursor) {
                                dispatch(setCursorPosition(selection.$cursor.pos));
                            }
                        },
                    }),
                }),
            ],
        });

    useEffect(() => {
        if (!editorRef.current) return;
        const state = initializeEditorState();
        const view = new EditorView(editorRef.current, {
            state,
            dispatchTransaction: (transaction) => {
                const newState = view.state.apply(transaction);
                view.updateState(newState);
                handleTextUpdate(newState.doc.textContent);
            },
        });
        viewRef.current = view;
        return () => view.destroy();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [node, handleAddBlock, dispatch]);

    const timerRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (currentNodeFromRedux?.id === node.id && viewRef.current && cursorPosition !== null) {
            if (timerRef.current) {
                clearTimeout(timerRef.current); // Очистить предыдущий таймер
            }

            timerRef.current = setTimeout(() => {
                const view = viewRef.current!;
                view.focus();
                const docSize = view.state.doc.content.size;

                if (cursorPosition >= 0 && cursorPosition <= docSize) {
                    view.dispatch(
                        view.state.tr.setSelection(TextSelection.create(view.state.doc, cursorPosition))
                    );
                }
            }, 0);
        }

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current); // Убедиться, что таймер очищен при размонтировании
            }
        };
    }, [currentNodeFromRedux, cursorPosition, node.id]);

    useEffect(() => {
        if (highlightedNodes) {
            viewRef.current?.dom.blur();
        }
    }, [highlightedNodes]);

    const handleBlur = () => {
        if (localText !== node.text) {
            dispatch(updateBlock({...node, text: localText})); // Обновление только при изменении текста
        }
    };


    const handleOnFocus = (event: React.MouseEvent<HTMLDivElement>) => {
        dispatch(setCurrentBlock(node));
        dispatch(setHighlightedNodes(null));
        if (viewRef.current) {
            const view = viewRef.current;
            const pos = view.posAtCoords({
                left: event.clientX,
                top: event.clientY,
            });

            if (pos) {
                dispatch(setCursorPosition(pos.pos));
            } else {
                // Если позиция не определена, сбрасываем в начало текста
                dispatch(setCursorPosition(0));
            }
        }
    };

    return <div ref={editorRef} onMouseDown={handleOnFocus} onBlur={handleBlur}
                className="1bg-yellow-100 w-full cursor-text"/>;
};

export default TextEditor;
