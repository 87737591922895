import {BlockType, CustomNode} from "../../components/types/types";

export const useConvertToToggledCheckbox = () => {
    return (nodeToConvert: CustomNode) => {
        if (!nodeToConvert) {
            console.error("Текущий блок не найден");
            return;
        }

        // Проверяем, что блок не является уже CheckboxNode
        if (nodeToConvert.blockType === BlockType.toggled_checkbox) {
            console.warn("Блок уже является Checkbox_Toggle");
            return;
        }

        // Создаем обновленный блок с типом 'checkbox' и добавлением поля 'checked'
        const updatedBlock: CustomNode = {
            ...nodeToConvert,
            blockType: BlockType.toggled_checkbox,  // Изменяем тип блока
            checked: false,
            minimized: false,
        };

        return updatedBlock;
    };
};
