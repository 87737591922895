import {useDispatch, useSelector} from "react-redux";
import {BlockType, CustomNode, InsertBlockOptions, NodeType} from "../../components/types/types";
import {RootState} from "../../redux";
import {setCurrentBlock, setCursorPosition, setGrid} from "../../redux/slices/keeps-slice";
import {useFindNodeById} from "./useFindNodeById";
import {useCreateWrapperAddBlock} from "./useCreateWrapperAddBlock";
import {v4 as uuidv4} from "uuid";

export const useAddBlock = () => {
    const dispatch = useDispatch();
    const createWrapperAddBlock = useCreateWrapperAddBlock();
    const findNodeById = useFindNodeById()

    const grid = useSelector((state: RootState) => state.keeps.present.grid);

    return (insertBlockOptions: InsertBlockOptions) => {
        if (!grid) {
            console.error("Сетка не инициализирована");
            return;
        }

        const newGrid = structuredClone(grid);

        const currentNode = findNodeById(insertBlockOptions.currentBlockId, newGrid);
        if (!currentNode) {
            console.error("Текущий узел не найден");
            return;
        }

        const parentNode = currentNode.parentId
            ? findNodeById(currentNode.parentId, newGrid)
            : null;

        // Создание нового блока с учетом blockType
        const newBlockNode: CustomNode = {
            id: uuidv4(),
            nodeType: NodeType.BLOCK,
            blockType: insertBlockOptions.blockType,
            children: [],
            parentId: insertBlockOptions.parentNodeId || insertBlockOptions.currentBlockId,
            pageId: currentNode.pageId,
            ...(insertBlockOptions.blockType === BlockType.text && {
                text: "",
            }),
            ...(insertBlockOptions.blockType === BlockType.checkbox && {
                text: "",
                checked: false,
            }),
        } as CustomNode;


        // Обработка случаев, где wrapperType указан
        if (insertBlockOptions.wrapperType) {
            const isSelfWrapper = insertBlockOptions.parentNodeId === insertBlockOptions.currentBlockId;

            if (!parentNode) {
                // Корневой элемент без родителя — создаем новый wrapper
                if (isSelfWrapper) {
                    if (insertBlockOptions.position !== "inside") {
                        createWrapperAddBlock(
                            currentNode,
                            newBlockNode,
                            insertBlockOptions.wrapperType,
                            insertBlockOptions.position,
                            currentNode.pageId,
                            null
                        );
                    } else {
                        console.error("Позиция 'inside' не поддерживается для корневого узла.");
                    }
                }
                return;
            }

            if (isSelfWrapper) {
                if (insertBlockOptions.position !== "inside") {
                    createWrapperAddBlock(
                        currentNode,
                        newBlockNode,
                        insertBlockOptions.wrapperType,
                        insertBlockOptions.position,
                        currentNode.pageId,
                        null
                    );
                } else {
                    console.error("Позиция 'inside' не поддерживается для обертки.");
                }
                return;
            }

            const indexInParent = parentNode.children.findIndex((child) => child.id === insertBlockOptions.currentBlockId);

            if (parentNode.nodeType !== insertBlockOptions.wrapperType) {
                if (insertBlockOptions.position === "inside") {
                    console.error("Позиция 'inside' не поддерживается для этого случая.");
                    return;
                }

                createWrapperAddBlock(
                    currentNode,
                    newBlockNode,
                    insertBlockOptions.wrapperType,
                    insertBlockOptions.position,
                    currentNode.pageId,
                    parentNode
                );
            } else {
                const insertIndex = insertBlockOptions.position === "before" ? indexInParent : indexInParent + 1;
                parentNode.children.splice(insertIndex, 0, newBlockNode);
            }
        } else {
            // Случай без wrapperType
            if (currentNode.nodeType !== NodeType.ROW && currentNode.nodeType !== NodeType.COL) {
                console.error(`Неверный тип узла (${currentNode.nodeType}). Ожидался "row" или "col".`);
                return;
            }

            if (insertBlockOptions.position === "before") {
                currentNode.children.unshift(newBlockNode);
            } else if (insertBlockOptions.position === "after") {
                currentNode.children.push(newBlockNode);
            } else {
                console.error("Неизвестная позиция:", insertBlockOptions.position);
                return;
            }
        }

        // Обновление состояния
        dispatch(setGrid(newGrid));
        dispatch(setCurrentBlock(newBlockNode));
        dispatch((setCursorPosition(1)));
    };
};
