import React from 'react';
import Modal from 'react-modal';
import {Provider} from 'react-redux';
import {CookiesProvider} from 'react-cookie';
import {AuthProvider} from './context/AuthContext';
import store from "./redux";
import CookieBanner from "./components/molecula/CookieBanner";
import AppRoutes from "./routes/AppRoutes";

Modal.setAppElement('#root');

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <CookiesProvider>
                <AuthProvider>
                    <CookieBanner/>
                    <AppRoutes/>
                </AuthProvider>
            </CookiesProvider>
        </Provider>
    );
}

export default App;
