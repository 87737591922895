import {useDispatch} from "react-redux";
import {setGrid} from "../../redux/slices/keeps-slice";
import {CustomNode, NodeType} from "../../components/types/types";
import {v4 as uuidv4} from "uuid";


export const useCreateWrapperAddBlock = () => {
    const dispatch = useDispatch();

    return (
        currentNode: CustomNode,
        newBlockNode: CustomNode,
        wrapperType: NodeType.BLOCK | NodeType.ROW | NodeType.COL,
        position: "before" | "after",
        pageId: string,
        parentNode: CustomNode | null
    ) => {
        if (!currentNode || !newBlockNode) {
            console.error("Один из узлов (currentNode или newBlockNode) отсутствует");
            return;
        }

        if (position !== "before" && position !== "after") {
            console.warn(`Некорректное значение position: ${position}. Используем "after" по умолчанию.`);
            position = "after";
        }

        const wrapperNode: CustomNode = {
            id: uuidv4(),
            nodeType: wrapperType,
            children: position === "before"
                ? [newBlockNode, currentNode]
                : [currentNode, newBlockNode],
            parentId: parentNode ? parentNode.id : null,
            pageId: pageId,
        };

        currentNode.parentId = wrapperNode.id;
        newBlockNode.parentId = wrapperNode.id;

        if (parentNode) {
            parentNode.children = parentNode.children || [];
            const indexInParent = parentNode.children.findIndex((child) => child.id === currentNode.id);
            if (indexInParent !== -1) {
                parentNode.children.splice(indexInParent, 1, wrapperNode);
            } else {
                console.error("Не удалось найти узел в дочерних элементах родителя");
            }
            dispatch(setGrid(parentNode));
        } else {
            if (!wrapperNode.children || wrapperNode.children.length === 0) {
                console.error("Ошибка: Новый корневой узел не имеет детей. Обновление отклонено.");
                return;
            }
            dispatch(setGrid(wrapperNode));
        }
    };
};
