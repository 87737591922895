import React from 'react';
import {NodeType, WrapperPosition} from "../../components/types/types";

interface AddBlockZonesForColProps {
    handleAddBlock: (position: WrapperPosition, wrapperType: NodeType.ROW | NodeType.COL) => void;
    handleAddBlockIntoWrapper: (position: WrapperPosition) => void;
}

const AddBlockZonesForCol: React.FC<AddBlockZonesForColProps> = ({
                                                                     handleAddBlock,
                                                                     handleAddBlockIntoWrapper
                                                                 }) => {
    return (
        <>

            <div className={`group col-top`}>
                <div
                    className={`absolute z-20 -top-0 left-0 w-full h-2 bg-green-5001 bg-transparent`}
                    onClick={() => handleAddBlockIntoWrapper("before")}></div>
                <div
                    className={`absolute z-30 top-0.5 left-0 w-full h-1 rounded bg-blue-5001 bg-transparent group-hover:bg-blue-500 transition duration-300 ease-in-out`}
                    onClick={() => handleAddBlockIntoWrapper("before")}></div>
            </div>

            <div className={`group col-bottom`}>
                <div
                    className={`absolute z-20 -bottom-2 left-0 w-full h-2 bg-green-5001 bg-transparent`}
                    onClick={() => handleAddBlockIntoWrapper("after")}></div>
                <div
                    className={`absolute z-30 -bottom-1.5 left-0 w-full h-1 rounded bg-blue-5001 bg-transparent group-hover:bg-blue-500 transition duration-300 ease-in-out`}
                    onClick={() => handleAddBlockIntoWrapper("after")}></div>
            </div>

            <div className={`group col-left`}>
                <div
                    className={`absolute z-20 -left-2 top-0 h-full w-2 bg-green-5001 bg-transparent`}
                    onClick={() => handleAddBlock("before", NodeType.ROW)}></div>
                <div
                    className={`absolute z-30 -left-1.5 top-0 h-full w-1 rounded bg-blue-5001 bg-transparent group-hover:bg-blue-500 transition duration-300 ease-in-out`}
                    onClick={() => handleAddBlock("before", NodeType.ROW)}></div>
            </div>

            <div className={`group col-right`}>
                <div
                    className={`absolute z-20 -right-2 top-0 h-full w-2 bg-green-5001 bg-transparent`}
                    onClick={() => handleAddBlock("after", NodeType.ROW)}></div>
                <div
                    className={`absolute z-30 -right-1.5 top-0 h-full w-1 rounded bg-blue-5001 bg-transparent group-hover:bg-blue-500 transition duration-300 ease-in-out`}
                    onClick={() => handleAddBlock("after", NodeType.ROW)}></div>
            </div>
        </>
    );
};

export default AddBlockZonesForCol;