import {useDispatch, useSelector} from "react-redux";
import {BlockType, CustomNode, InsertBlockOptions, NodeType} from "../../components/types/types";
import {RootState} from "../../redux";
import {setGrid} from "../../redux/slices/keeps-slice";
import {useFindNodeById} from "./useFindNodeById";
import {v4 as uuidv4} from "uuid";


export const useAddSubBlock = () => {
    const dispatch = useDispatch();
    const findNodeById = useFindNodeById();

    const grid = useSelector((state: RootState) => state.keeps.present.grid); // Используем present для истории

    return (insertBlockOptions: InsertBlockOptions) => {
        if (!grid) {
            console.error("Сетка не инициализирована");
            return;
        }

        const newGrid = structuredClone(grid);

        const currentNode = findNodeById(insertBlockOptions.currentBlockId, newGrid);
        if (!currentNode) {
            console.error("Текущий узел не найден");
            return;
        }

        const newBlockNode: CustomNode = {
            id: uuidv4(),
            nodeType: NodeType.BLOCK,
            blockType: insertBlockOptions.blockType,
            children: [],
            parentId: insertBlockOptions.currentBlockId,
            pageId: currentNode.pageId,
            ...(insertBlockOptions.blockType === BlockType.text && {
                text: "Новый текст",
            }),
            ...(insertBlockOptions.blockType === BlockType.checkbox && {
                text: "Новый чекбокс",
                checked: false,
            }),
        } as CustomNode;

        currentNode.children = [newBlockNode, ...currentNode.children];

        // Отправляем новое состояние сетки в Redux
        dispatch(setGrid(newGrid));
    };
};
