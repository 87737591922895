import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {useFindNodeById} from "./useFindNodeById";
import {setGrid} from "../../redux/slices/keeps-slice";
import {CustomNode, InsertBlockOptions} from "../../components/types/types";
import useRemoveEmptyWrappers from "./useRemoveEmptyWrappers";
import {useCreateWrapperMoveBlock} from "./useCreateWrapperMoveBlock";

const isDescendant = (parent: CustomNode, childId: string): boolean =>
    parent.children?.some((child) => child.id === childId || isDescendant(child, childId)) ?? false;


export const useMoveBlock = () => {
    const dispatch = useDispatch();
    const {removeEmptyWrappers} = useRemoveEmptyWrappers();
    const findNodeById = useFindNodeById();
    const grid = useSelector((state: RootState) => state.keeps.present.grid); // Используем present для доступа к текущему состоянию
    const createWrapperMoveBlock = useCreateWrapperMoveBlock();

    return (insertOptions: InsertBlockOptions) => {
        if (!grid) {
            console.error("Сетка не инициализирована");
            return;
        }

        const newGrid: CustomNode = structuredClone(grid);
        const selectedBlock = findNodeById(insertOptions.currentBlockId, newGrid);
        const targetBlock = insertOptions.targetBlockId
            ? findNodeById(insertOptions.targetBlockId, newGrid)
            : null;
        const parentNode = findNodeById(insertOptions.parentNodeId, newGrid);

        if (!selectedBlock) {
            console.error("Выбранный блок не найден");
            return;
        }

        if (targetBlock && isDescendant(selectedBlock, targetBlock.id)) {
            console.error("Невозможно переместить родительский блок в один из его дочерних блоков");
            return;
        }

        if (selectedBlock.parentId) {
            const currentParent = findNodeById(selectedBlock.parentId, newGrid);
            if (currentParent?.children) {
                currentParent.children = currentParent.children.filter(
                    (child) => child.id !== selectedBlock.id
                );
                removeEmptyWrappers(newGrid, currentParent);
            }
        }

        if (insertOptions.position === "inside" && targetBlock) {
            if (!targetBlock.children) {
                targetBlock.children = [];
            }
            targetBlock.children.push(selectedBlock);
            selectedBlock.parentId = targetBlock.id;
        } else {
            let newParent = parentNode;

            if (
                insertOptions.wrapperType &&
                (!targetBlock || targetBlock.nodeType !== insertOptions.wrapperType) &&
                (!parentNode || parentNode.nodeType !== insertOptions.wrapperType)
            ) {
                if (!targetBlock) {
                    console.error("Целевой блок отсутствует для создания обёртки");
                    return;
                }
                newParent = createWrapperMoveBlock(
                    insertOptions.wrapperType,
                    targetBlock,
                    parentNode,
                    newGrid,
                    selectedBlock.pageId,
                );
            }

            if (!newParent?.children) {
                console.error("Невозможно переместить блок: новый родитель не поддерживает дочерние элементы");
                return;
            }

            const insertIndex = targetBlock
                ? newParent.children.findIndex((child) => child.id === targetBlock.id)
                : newParent.children.length;

            const position = insertOptions.position === "before" ? insertIndex : insertIndex + 1;
            newParent.children.splice(position, 0, selectedBlock);
            selectedBlock.parentId = newParent.id;
        }

        // Отправляем действие на изменение сетки
        dispatch(setGrid(newGrid));
    };
};

