import {useCallback} from "react";
import {CustomNode, NodeType} from "../../components/types/types";
import {useFindNodeById} from "./useFindNodeById";

const useRemoveEmptyWrappers = () => {
    const findNodeById = useFindNodeById();

    const removeEmptyWrappers = useCallback(
        (grid: CustomNode, node: CustomNode) => {
            const processNode = (currentNode: CustomNode, currentGrid: CustomNode) => {
                const parent = currentNode.parentId ? findNodeById(currentNode.parentId, currentGrid) : null;

                // console.log("Обрабатываем узел:", currentNode.id, "родитель:", parent);
                if (isEmptyWrapper(currentNode)) {
                    removeWrapperFromParent(currentNode, parent, currentGrid);
                    return;
                }

                if (hasSingleChild(currentNode)) {
                    replaceWrapperWithSingleChild(currentNode, parent, currentGrid, removeEmptyWrappers);
                    return;
                }

                currentNode.children?.forEach((child) => processNode(child, currentGrid));
            };

            processNode(node, grid);
        },
        [findNodeById]
    );

    return {removeEmptyWrappers};
};

// Проверка, является ли узел пустой оберткой
const isEmptyWrapper = (node: CustomNode): boolean => {
    return (node.nodeType === NodeType.ROW || node.nodeType === NodeType.COL) && node.children?.length === 0;
};

// Проверка, имеет ли узел единственного ребенка
const hasSingleChild = (node: CustomNode): boolean => {
    return (node.nodeType === NodeType.ROW || node.nodeType === NodeType.COL) && node.children?.length === 1;
};

// Удаление пустой обертки из родительского узла
const removeWrapperFromParent = (node: CustomNode, parent: CustomNode | null, grid: CustomNode) => {
    console.log("Удаляем пустую обертку:", node.id, "из родителя", parent?.id);

    if (parent?.children) {
        parent.children = parent.children.filter((child) => child.id !== node.id);
        console.log(`Удалена пустая обертка ${node.id} из родителя ${parent.id}`);
    } else if (!node.parentId && grid.children) {
        grid.children = grid.children.filter((child) => child.id !== node.id);
        console.log(`Удалена пустая обертка ${node.id} из корня`);
    }
};

// Замена пустой обертки на единственного ребенка
const replaceWrapperWithSingleChild = (
    node: CustomNode,
    parent: CustomNode | null,
    grid: CustomNode,
    removeEmptyWrappers: (grid: CustomNode, node: CustomNode) => void
) => {
    const singleChild = node.children[0];
    if (singleChild.nodeType !== NodeType.BLOCK) {
        console.log("Заменяем обертку на единственного ребенка:", node.id, "на", singleChild.id);

        if (parent?.children) {
            const index = parent.children.findIndex((child) => child.id === node.id);
            if (index !== -1) {
                parent.children.splice(index, 1, singleChild);
            }
        } else if (!node.parentId && grid.children) {
            const gridIndex = grid.children.findIndex((child) => child.id === node.id);
            if (gridIndex !== -1) {
                grid.children.splice(gridIndex, 1, singleChild);
            }
        }

        singleChild.parentId = parent?.id ?? null;

        // Рекурсивно проверяем родителя
        removeEmptyWrappers(grid, parent ?? singleChild);
    }
};

export default useRemoveEmptyWrappers;
