import {useSelector} from "react-redux";
import {RootState} from "../../redux";
import {CustomNode} from "../../components/types/types";

// Рекурсивная чистая функция для поиска узла
const recursiveFindNodeById = (nodeId: string, node: CustomNode): CustomNode | null => {
    if (node.id === nodeId) return node;
    for (const child of node.children) {
        const found = recursiveFindNodeById(nodeId, child);
        if (found) return found;
    }
    return null;
};

// Хук для поиска узла
export const useFindNodeById = () => {
    const grid = useSelector((state: RootState) => state.keeps.present.grid);

    return (nodeId: string, node?: CustomNode): CustomNode | null => {
        const rootNode = node || grid; // Если узел не передан, используем grid
        if (!rootNode) return null;
        return recursiveFindNodeById(nodeId, rootNode);
    };
};
