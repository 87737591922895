import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {setCurrentBlock, setHighlightedNodes, updatePages} from "../../redux/slices/keeps-slice";

const RenderKeepsTitle: React.FC = () => {
    const dispatch = useDispatch();

    const currentPage = useSelector((state: RootState) => state.keeps.present.currentPage);

    const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newTitle = e.target.value;

        if (currentPage) {
            dispatch(updatePages({...currentPage, title: newTitle}));
        }
    };

    const handleOnFocus = () => {
        dispatch(setCurrentBlock(null));
        dispatch(setHighlightedNodes(null));
    };

    return (
        <textarea
            value={currentPage?.title || ""}
            onChange={handleTitleChange}
            onFocus={handleOnFocus}
            className="w-full text-4xl font-bold text-left text-slate-800 bg-transparent resize-none focus:outline-none 1bg-yellow-500"
            placeholder="Введите заголовок страницы"
        />
    );
};

export default RenderKeepsTitle;
