import React from 'react';
import {NodeType, WrapperPosition} from "../../components/types/types";

interface AddBlockZonesForBlockProps {
    handleAddBlock: (position: WrapperPosition, wrapperType: NodeType.ROW | NodeType.COL) => void;
}

const AddBlockZonesForBlock: React.FC<AddBlockZonesForBlockProps> = ({
                                                                         handleAddBlock,
                                                                     }) => {
    return (
        <>
            <div className={`group block-top cursor-pointer`}>
                <div
                    className={`absolute z-40 -top-1 left-0 w-full h-1.5 bg-red-5001 bg-transparent`}
                    onClick={() => handleAddBlock("before", NodeType.COL)}></div>
                <div
                    className={`absolute z-10 -top-1.5 left-0 w-full h-1 rounded bg-blue-5001 bg-transparent group-hover:bg-blue-300 transition duration-300 ease-in-out`}
                    onClick={() => handleAddBlock("before", NodeType.COL)}></div>
            </div>

            <div className={`group block-bottom cursor-pointer`}>
                <div
                    className={`absolute z-40 -bottom-1 left-0 w-full h-1.5 bg-red-5001 bg-transparent`}
                    onClick={() => handleAddBlock("after", NodeType.COL)}></div>
                <div
                    className={`absolute z-10 -bottom-1.5 left-0 w-full h-1 rounded bg-blue-5001 bg-transparent group-hover:bg-blue-300 transition duration-300 ease-in-out`}
                    onClick={() => handleAddBlock("after", NodeType.COL)}></div>
            </div>

            <div className={`group block-left cursor-pointer`}>
                <div
                    className={`absolute z-40 -left-1 top-0 h-full w-1.5 bg-red-5001 bg-transparent`}
                    onClick={() => handleAddBlock("before", NodeType.ROW)}></div>
                <div
                    className={`absolute z-10 -left-1.5 top-0 h-full w-1 rounded bg-blue-5001 bg-transparent group-hover:bg-blue-300 transition duration-300 ease-in-out`}
                    onClick={() => handleAddBlock("before", NodeType.ROW)}></div>
            </div>

            <div className={`group block-right cursor-pointer`}>
                <div
                    className={`absolute z-40 -right-1 top-0 h-full w-2 bg-red-5001 bg-transparent`}
                    onClick={() => handleAddBlock("after", NodeType.ROW)}></div>
                <div
                    className={`absolute z-10 -right-1.5 top-0 h-full w-1 rounded bg-blue-5001 bg-transparent group-hover:bg-blue-300 transition duration-300 ease-in-out`}
                    onClick={() => handleAddBlock("after", NodeType.ROW)}></div>
            </div>
        </>
    );
};

export default AddBlockZonesForBlock;