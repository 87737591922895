import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {setGrid} from "../../redux/slices/keeps-slice";
import {CustomNode, NodeType} from "../../components/types/types";
import {useFindNodeById} from "./useFindNodeById";
import useRemoveEmptyWrappers from "./useRemoveEmptyWrappers";
import getDefaultGrid from "../../utils/keeps/getDefaultGrid";

export const useDeleteBlock = () => {
    const dispatch = useDispatch();
    const {removeEmptyWrappers} = useRemoveEmptyWrappers();
    const findNodeById = useFindNodeById();
    const grid = useSelector((state: RootState) => state.keeps.present.grid);
    const currentPage = useSelector((state: RootState) => state.keeps.present.currentPage);

    return (nodeId: string) => {
        if (!grid) {
            console.error("Сетка не инициализирована");
            return;
        }

        const newGrid = structuredClone(grid);
        const targetNode = findNodeById(nodeId, newGrid);

        if (!targetNode) {
            console.error("Узел не найден");
            return;
        }

        // Если targetNode не имеет родителя, значит это узел без родительского элемента
        if (targetNode.parentId === null) {
            console.log(`Удаляем корневой узел: ${nodeId}`);

            // Если корневой узел, просто удаляем его из сетки
            const removeNodeById = (nodeId: string, grid: CustomNode) => {
                if (grid.id === nodeId) return null; // Удаляем сам корневой узел
                grid.children = grid.children.filter(child => child.id !== nodeId);
                return grid;
            };

            // Обновляем сетку, удаляя корневой узел
            const updatedGrid = removeNodeById(nodeId, newGrid);
            if (updatedGrid === null || Object.keys(updatedGrid).length === 0) {
                if (currentPage) {
                    dispatch(setGrid(getDefaultGrid(currentPage.id)));
                } else {
                    console.error("currentPage is null");
                }
                return;
            }

            dispatch(setGrid(updatedGrid));
            return;
        }

        // Стандартная логика для обычного узла с parentId != null
        const parentNode = findNodeById(targetNode.parentId ?? '', newGrid);
        if (!parentNode) {
            console.error("Родительский узел не найден, не могу удалить элемент");
            return;
        }


        // Удаляем узел из родителя
        parentNode.children = parentNode.children.filter((child) => child.id !== nodeId);

        // Используем хук для удаления пустых обёрток
        removeEmptyWrappers(newGrid, parentNode);

        // Если у родителя (row/col) остался только один дочерний элемент
        if ((parentNode.nodeType === NodeType.ROW || parentNode.nodeType === NodeType.COL) && parentNode.children.length === 1) {
            const singleChild = parentNode.children[0];
            const grandParentNode = findNodeById(parentNode.parentId ?? '', newGrid);

            if (grandParentNode) {
                // Заменяем родителя на единственного ребенка
                grandParentNode.children = grandParentNode.children.map((child) =>
                    child.id === parentNode.id ? singleChild : child
                );
                // Обновляем parentId у оставшегося child
                singleChild.parentId = grandParentNode.id;
            } else {
                // Если grandParentNode отсутствует, значит parentNode — это корень
                console.warn("Родитель — корень, обновляем сетку.");
                singleChild.parentId = null;
                dispatch(setGrid(singleChild)); // Устанавливаем оставшийся узел как новый root
                return;
            }
        }

        // Обновляем сетку
        dispatch(setGrid(newGrid));
    };
};
