import {useDispatch} from "react-redux";
import {BlockType, CustomNode} from "../../components/types/types";
import {updateBlock} from "../../redux/slices/keeps-slice";
import {useConvertSubBlockToBlock} from "./useConvertSubBlockToBlock";
import {useDeleteBlock} from "./useDeleteBlock";

export const useConvertBlockOnBackspace = () => {
    const dispatch = useDispatch();
    const convertToBlock = useConvertSubBlockToBlock();
    const deleteBlock = useDeleteBlock();

    return (node: CustomNode) => {
        // Проверка типа блока
        switch (node.blockType) {
            case BlockType.checkbox:
            case BlockType.toggled_checkbox:
            case BlockType.toggle: {
                // Преобразование чекбокса в обычный текстовый блок
                const updatedNode = {
                    ...node,
                    blockType: BlockType.text, // Сменяем тип на обычный блок
                };
                dispatch(updateBlock(updatedNode));
                break;
            }
            case BlockType.text: {
                const updatedNode = {
                    ...node,
                    blockType: BlockType.text, // Сменяем тип на обычный блок
                };
                try {
                    convertToBlock(updatedNode)
                    dispatch(updateBlock(updatedNode));
                } catch (error) {
                    deleteBlock(updatedNode.id);
                }
                break;
            }
            default: {
                console.warn(`useConvertBlockOnBackspace: Unknown block type: ${node.blockType}`);
                break;
            }
        }
    };
};
