import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid'; // Для генерации UUID
import {useDispatch, useSelector} from 'react-redux';
import {clientWsPage} from '../../../api/client-ws';
import {Page} from '../../types/types';
import {setCurrentPage, setGrid, setPages} from "../../../redux/slices/keeps-slice";
import {RootState} from "../../../redux";
import getDefaultGrid from "../../../utils/keeps/getDefaultGrid";

const KeepsSidebarPages: React.FC = () => {
    const dispatch = useDispatch();

    const pages = useSelector((state: RootState) => state.keeps.present.pages)

    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        const fetchRootPage = async () => {
            try {
                const root = await clientWsPage.getRootPage();
                dispatch(setPages(root.data));
            } catch (error) {
                console.error('Ошибка при получении Root Page:', error);
            }
        };

        fetchRootPage();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const currentPageId = location.pathname.split('/').pop();
        if (currentPageId && pages) {
            const selectedPage = pages.pages?.find(page => page.id === currentPageId) || null;
            dispatch(setCurrentPage(selectedPage));
        }
    }, [location.pathname, pages, dispatch]);

    const handleAddPage = async () => {
        if (!pages) return;

        // Создаём новую страницу
        const newPage: Page = {
            id: uuidv4(),
            title: 'Без названия',
            pages: [],
            minimized: true,
        };

        // Обновляем rootPage
        const updatedRootPage = {
            ...pages,
            pages: [...(pages.pages || []), newPage],
        };

        dispatch(setPages(updatedRootPage));
        await clientWsPage.update(updatedRootPage);

        // Переходим на новую страницу
        navigate(`/keeps/${newPage.id}`);
    };

    const handlePageClick = (pageId: string) => {
        const selectedPage = pages?.pages?.find(page => page.id === pageId) || null;
        dispatch(setCurrentPage(selectedPage));
        navigate(`/keeps/${pageId}`);
    };

    const handleDeletePage = (pageId: string) => {
        // Рекурсивная функция для удаления страницы из дерева
        const deletePage = (page: Page | null, pageId: string): Page | null => {
            if (!page) return null; // Если объект пустой или null, возвращаем null

            if (page.id === pageId) return null; // Удаляем текущую страницу, если ID совпадает

            return {
                ...page,
                pages: page.pages
                    ?.map((childPage) => deletePage(childPage, pageId)) // Рекурсивно обрабатываем дочерние страницы
                    .filter((childPage): childPage is Page => childPage !== null) || [], // Убираем null и явно указываем тип
            };
        };

        const updatedPages = deletePage(pages, pageId); // Обрабатываем верхний уровень

        // Обновляем состояние через dispatch
        dispatch(setPages(updatedPages));
        dispatch(setGrid(getDefaultGrid(pageId)));
    };


    return (
        <ul>
            <aside className="flex flex-col">
                {/*Верхний блок \ добавление страниц и поиск*/}
                <div className="p-4 mb-2 border-b border-gray-300">
                    <button
                        onClick={handleAddPage}
                        className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                        + Добавить
                    </button>
                </div>

                {/*Центральная часть здесь страницы*/}
                <div className="flex-1 overflow-y-auto items-start">
                    {pages?.pages?.length ? (
                        <ul>
                            {pages.pages.map((page) => (
                                <li
                                    key={page.id}
                                    className={`px-1 mb-1 text-slate-600 rounded-md ${
                                        location.pathname === `/keeps/${page.id}`
                                            ? 'bg-blue-100 text-slate-700'
                                            : 'hover:bg-slate-100 hover:text-slate-800'
                                    }`}
                                >
                                    <div className="flex items-center group">
                                        <button
                                            onClick={() => handlePageClick(page.id)}
                                            className={`font-medium truncate overflow-hidden w-full text-left pr-6 transition-all duration-200 ${
                                                location.pathname === `/keeps/${page.id}` ? 'text-slate-700' : ''
                                            }`}
                                        >
                                            {page.title || 'Без названия'}
                                        </button>
                                        <button
                                            onClick={() => handleDeletePage(page.id)}
                                            className="ml-2 p-1 text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                                            aria-label="Удалить страницу"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-4 h-4"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-gray-600">Нет доступных страниц</p>
                    )}
                </div>
                {/*Нижняя часть здесь будет корзина*/}
            </aside>
        </ul>
    );
};

export default KeepsSidebarPages;
