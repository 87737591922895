import {v4 as uuidv4} from "uuid";
import {BlockType, CustomNode, NodeType} from "../../components/types/types";

const getDefaultGrid = (pageId: string): CustomNode => {
    const rootId = uuidv4();
    const blockId = uuidv4();

    return {
        id: rootId,
        nodeType: NodeType.COL,
        children: [getDefaultTextBlock(rootId, blockId, pageId)],
        parentId: null,
        pageId,
    };
};

// Создаем базовый текстовый блок
const getDefaultTextBlock = (rootId: string, blockId: string, pageId: string): CustomNode => ({
    id: blockId,
    nodeType: NodeType.BLOCK,
    blockType: BlockType.text,
    text: "",
    children: [],
    parentId: rootId,
    pageId,
});

export default getDefaultGrid;
