import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {setGrid} from "../../redux/slices/keeps-slice";
import {useFindNodeById} from "./useFindNodeById";
import {CustomNode, NodeType} from "../../components/types/types";

export const useConvertSubBlockToBlock = () => {
    const dispatch = useDispatch();
    const findNodeById = useFindNodeById();
    const grid = useSelector((state: RootState) => state.keeps.present.grid);

    return (currentBlock: CustomNode) => {
        if (!grid) {
            console.error("Сетка не инициализирована");
            return;
        }

        const newGrid: CustomNode = structuredClone(grid);

        // Проверяем, что блок передан и у него есть родитель
        if (!currentBlock) {
            console.error("Текущий блок не найден");
            return;
        }

        if (!currentBlock.parentId) {
            console.error("Блок уже верхнего уровня");
            return;
        }

        // Находим родителя текущего блока
        let parent = findNodeById(currentBlock.parentId, newGrid);
        if (!parent || !parent.children) {
            console.error("Родитель не найден или не имеет детей");
            return;
        }

        // Удаляем текущий блок из детей родителя
        const currentIndex = parent.children.findIndex((child) => child.id === currentBlock.id);
        if (currentIndex === -1) {
            console.error("Блок не найден среди детей родителя");
            return;
        }
        parent.children.splice(currentIndex, 1);

        // Проверяем, является ли родитель "col"
        while (parent.nodeType === NodeType.COL) {
            if (!parent.parentId) {
                throw new Error("Родитель корневой, перемещение блока не выполняется");
            }
            parent = findNodeById(parent.parentId, newGrid);
            if (!parent || !parent.children) {
                throw new Error("Гранд-родитель не найден");
            }
        }

        // Теперь parent указывает на блок, в который нужно добавить текущий блок
        if (parent.parentId) {
            const grandParent = findNodeById(parent.parentId, newGrid);
            if (!grandParent || !grandParent.children) {
                console.error("Гранд-родитель не найден");
                return;
            }

            // Находим индекс родителя в массиве детей гранд-родителя
            const parentIndex = grandParent.children?.findIndex((child) => child.id === parent?.id);
            if (parentIndex === -1) {
                console.error("Родитель не найден среди детей гранд-родителя");
                return;
            }

            // Вставляем currentBlock после родителя
            currentBlock.parentId = grandParent.id;
            grandParent.children.splice(parentIndex + 1, 0, currentBlock);
        } else {
            currentBlock.parentId = null; // Устанавливаем как корневой
        }

        // Обновляем состояние
        dispatch(setGrid(newGrid));
    };
};
