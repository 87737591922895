import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import RenderNode from "../../../utils/keeps/RenderNode";
import {RootState} from "../../../redux";
import {useAddSubBlock} from "../../../hooks/keeps/useAddSubBlock";
import {useDeleteBlock} from "../../../hooks/keeps/useDeleteBlock";
import {useAddBlock} from "../../../hooks/keeps/useAddBlock";
import {useMoveBlock} from "../../../hooks/keeps/useMoveBlock";
import {ActionCreators} from "redux-undo"; // Импортируем экшены undo/redo
import {debounce} from "lodash";
import {clientWsPage, clientWsPageContent} from "../../../api/client-ws";
import {setCurrentPage, setGrid, setPages} from "../../../redux/slices/keeps-slice";
import getDefaultGrid from "../../../utils/keeps/getDefaultGrid";
import {useLocation} from "react-router-dom";
import RenderKeepsTitle from "../../../utils/keeps/RenderKeepsTitle";
import {useHeader} from "../../../context/HeaderContext";
import {Helmet} from "react-helmet";

const Keeps: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {setHeaderContent} = useHeader();


    // Берём `present` из состояния, чтобы использовать текущую версию grid
    const grid = useSelector((state: RootState) => state.keeps.present.grid, shallowEqual);
    const currentPage = useSelector((state: RootState) => state.keeps.present.currentPage);
    const pages = useSelector((state: RootState) => state.keeps.present.pages)


    useEffect(() => {
        setHeaderContent('');

        const fetchRootPage = async () => {
            try {
                const root = await clientWsPage.getRootPage();
                dispatch(setPages(root.data));
                console.log('Root Page:', root);
            } catch (error) {
                console.error('Ошибка при получении Root Page:', error);
            }
        };

        fetchRootPage();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const currentPageId = location.pathname.split('/').pop();
        if (currentPageId && pages) {
            const selectedPage = pages.pages?.find(page => page.id === currentPageId) || null;
            dispatch(setCurrentPage(selectedPage));
        }
    }, [location.pathname, pages, dispatch]);

    // Дебаунс-функция для сохранения
    const saveDebounced = debounce(async (updatedGrid, updatedPages) => {
        try {
            if (currentPage?.id) {
                await clientWsPageContent.updatePageContent(currentPage.id, updatedGrid);
                await clientWsPage.update(updatedPages);
                console.log("Grid saved successfully");
            } else {
                console.error("currentPage.id is undefined");
            }
        } catch (error) {
            console.error("Failed to save grid:", error);
        }
    }, 7000);

    useEffect(() => {
        // Сохраняем grid при изменении
        saveDebounced(grid, pages);

        return () => {
            saveDebounced.cancel(); // Очищаем debounce при размонтировании
        };
        // eslint-disable-next-line
    }, [grid, pages]);

    useEffect(() => {
        const fetchPageContent = async () => {
            if (!currentPage?.id) return;

            try {
                const content = await clientWsPageContent.getPageContentById(currentPage.id);
                if (content.data.id === null) {
                    dispatch(setGrid(getDefaultGrid(currentPage.id)));
                } else {
                    dispatch(setGrid(content.data));
                }
            } catch (error) {
                console.error("Failed to load page content:", error);
            }
        };

        fetchPageContent();
        //eslint-disable-next-line
    }, [currentPage?.id, dispatch]);

    const addSubBlock = useAddSubBlock();
    const deleteBlock = useDeleteBlock();
    const addBlock = useAddBlock();
    const moveBlock = useMoveBlock();


    return (
        <div className="min-h-screen max-w-5xl mx-auto bg-white shadow-md rounded-md p-4">
            <Helmet>
                <title>{currentPage?.title || ''} | ChatHolder</title>
            </Helmet>
            <header className="mb-6 flex justify-between items-center space-x-4">
                <RenderKeepsTitle/>
                <button
                    onClick={() => dispatch(ActionCreators.undo())}
                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md shadow hover:bg-gray-300"
                >
                    Отменить (Ctrl+Z)
                </button>
                <button
                    onClick={() => dispatch(ActionCreators.redo())}
                    className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md shadow hover:bg-gray-300"
                >
                    Повторить (Ctrl+Shift+Z)
                </button>
            </header>
            {grid && (
                <RenderNode
                    node={grid}
                    addSubBlock={addSubBlock}
                    deleteBlock={deleteBlock}
                    addBlock={addBlock}
                    moveBlock={moveBlock}
                />
            )}
        </div>
    );
};

export default Keeps;